import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import planet from '../../../assets/images/icons/Planet.svg';

import logo from '../../../assets/images/gowise_logo.svg';
import './Header.scss';
import { FormattedMessage } from 'react-intl';

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === null;

  const onChangeLanguage = (e) => {
    changeLanguage(e);
  };

  return (
    <div className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            style={{
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Link to="/" dropdown-toggleclassName={isHomepage ? 'active' : ''}>
              <img
                src={logo}
                className="homepagelogo"
                alt="GO Wise EV Logo"
                style={{ maxWidth: '190px' }}
              />
            </Link>
          </div>
          <NavbarToggler
            onClick={() => setCollapse(!collapse)}
            aria-label="Toggle Dropdown"
          />
          <Collapse isOpen={collapse} navbar>
            <Nav navbar>
              <NavItem>
                <a href="https://gowise.com/blog/" rel="noopener noreferrer">
                  <span>
                    <FormattedMessage
                      id="faq"
                      defaultMessage="EV ED."
                      description="EV ED."
                    />
                  </span>
                </a>
              </NavItem>
              <NavItem>
                <Link
                  to="/compare-vehicles"
                  className={activePage === 'compare-vehicles' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="compareVehicles"
                      defaultMessage="COMPARE VEHICLES"
                      description="Compare Vehicles Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/vehicles"
                  className={activePage === 'vehicles' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehiclesHeader"
                      defaultMessage="Shop Electric"
                      description="Shop Electric Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === 'incentives' ? 'Active Page' : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="incentives"
                      defaultMessage="INCENTIVES"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <Link
                  to="/charging-stations"
                  className={activePage === 'map' ? 'active' : ''}
                >
                  <span>
                    <FormattedMessage
                      id="chargingStations"
                      defaultMessage="Charging"
                      description="Charging Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <a
                  href="http://gowise.com/home-charging"
                  rel="noopener noreferrer"
                >
                  <span>
                    <FormattedMessage
                      id="homeChargers"
                      defaultMessage="HOME CHARGING"
                      description="Home Charging Header"
                    />
                  </span>
                </a>
              </NavItem>
            </Nav>
          </Collapse>
          <ul style={{ padding: '0' }}>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret aria-label="Language Toogle">
                <span>
                  <img src={planet} alt=""></img>
                </span>
              </DropdownToggle>
              <DropdownMenu
                style={{ color: 'white', backgroundColor: '#403382' }}
              >
                <DropdownItem
                  className={language === 'EN' ? 'item active' : 'off item'}
                  onClick={() => onChangeLanguage('EN')}
                >
                  EN
                </DropdownItem>
                <DropdownItem
                  className={language === 'ES' ? 'item active' : 'off item'}
                  onClick={() => onChangeLanguage('ES')}
                >
                  ES
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
